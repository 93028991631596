import {Component} from "react";
import {Button, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Container} from "@material-ui/core";
import {Link, useMatch, useResolvedPath} from "react-router-dom";

function CustomLink({ children, to, ...props }: LinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <div>
            <Link
                to={to}
                {...props}
                className={ match ? "active-link "+(props.className??"") : props.className }
            >
                {children}
            </Link>
        </div>
    );
}

export class NavigationBar extends Component{
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }
    render() {
        return (<Navbar collapseOnSelect expand="lg" bg="none">
            <Container>
                {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <CustomLink to="/" className={'nav-link'}><img src="/logo.png" style={{height:30}} alt="Afrix logo"/> Afrix</CustomLink>
                    </Nav>
                    <Nav className={'align-items-baseline'}>
                        <CustomLink activeClassName='active' className={'nav-link'} to={'/download'}>Download</CustomLink>
                        <CustomLink to="/developers" className={'nav-link'}>Developer</CustomLink>
                        <CustomLink to="/contact" className={'nav-link'}>Contact us</CustomLink>
                        <NavDropdown title="Terms & Conditions" id="collasible-nav-dropdown">
                            <Link to="/terms" className={'dropdown-item'} role={'button'}>Terms & Conditions</Link>
                            <Link to={'/privacy'} className={'dropdown-item'} role={'button'}>Privacy Policy</Link>
                            {/*<NavDropdown.Divider />*/}
                            {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                        </NavDropdown>
                        <Nav.Link href="#deets" className={'cl-link'}>
                            <a href={'#'} style={{border:'none',borderRadius:'4px',padding:'10px 30px'}} className={'a-link light-bg-dark'}>
                            Documentation
                        </a></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>);
    }
}


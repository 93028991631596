import './App.css';
import {Component} from "react";
import {NavigationBar} from "./NavigationBar";
import {Link} from "react-router-dom";

class App extends Component{

    constructor(props) {
        super(props);
        this.state = {
            message:"map"
        }
    }


    render() {
        return (
            <div className="">
                <section className="mt-5 App" style={{paddingBottom:'70px'}}>
                    <div className="p-3">
                        <div className={'p-3'}>
                            <img src="/logo.png" style={{height:120}} alt="Afrix logo"/>
                        </div>
                        <h1 className="mt-3">Share Without Insecurity</h1>
                        <div className={'row justify-content-center'}>
                            <div className={'col-md-9'}>
                                <h3
                                    className="mt-lg-3 text-color-dark" style={{fontSize:'17px',fontWeight:400}}>State-of-the-art end-to-end encryption keeps your conversations secure. We can't read your messages or listen to your calls, and no one else can either. Privacy isn’t an optional mode — it’s just the way that AFRIX works. Every message, every call, every time
                                </h3>
                            </div>
                        </div>
                        <div className="mt-lg-5">
                            <Link to={'/downloading#'+this.getOS()} href={'#'} className={'btn btn-afrix'} style={{marginLeft:'10px',marginTop:'10px',borderRadius: '8px',padding:'8px 20px'}}>

                                Download for {this.getOS()}
                            </Link>
                            <Link to={'/download'} className={'btn btn-light-x'}
                                style={{marginLeft:'10px',marginTop:'10px',border: '1px solid #E1E4E8',backgroundColor:'#ffffff',borderRadius: '8px',padding:'8px 20px'}}>

                                Download For Other Platforms
                            </Link>
                        </div>
                    </div>
                </section>


            </div>
        );
    }


    getOS() {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }
}

export default App;

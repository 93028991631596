import {Component} from "react";
import {CircularProgress, Container} from "@material-ui/core";

export class Downloading extends Component{
    render() {
        return <div className={'App'}><Container>
            <div className={'p-5'}>
                <div className={'p-5'}>
                    <div className={'p-5'}>
                        <CircularProgress />
                    </div>
                </div>

                <h1>Downloading App .....</h1>
            </div>
            <div style={{height: '100px'}}/>
        </Container></div>;
    }
}
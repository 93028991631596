import {Component} from "react";
import {Container} from "@material-ui/core";

export class Contact extends Component{
    render() {
        return <div>
            <Container>
                <div className={'row justify-content-center'}>
                    <form action="" className={'col-md-6 mt-5'}>

                        <div className={'text-center mb-5'}>
                            <h1>Contact Us</h1>
                        </div>
                       <div className={'row'}>
                           <div className={'col-md-6'}>
                               <div className={'form-group mb-3'}>
                                   <label htmlFor="email" className={'form-label'}>Email</label>
                                   <input type="email" className={'form-control'} required={'required'} placeholder={'Email Address'}/>
                               </div>
                           </div>
                           <div className={'col-md-6'}>
                               <div className={'form-group mb-3'}>
                                   <label htmlFor="email" className={'form-label'}>First & Last Name</label>
                                   <input type="text" className={'form-control'} required={'required'} placeholder={'Full Names'}/>
                               </div>
                           </div>
                       </div>
                        <div className={'form-group mb-3'}>
                            <label htmlFor="email" className={'form-label'}>Message</label>
                            <textarea className={'form-control'} required={'required'} rows={5} placeholder={'Message'}/>
                        </div>
                        <div className={'clearfix'}>
                            <button type={'submit'} className={'btn btn-afrix float-right'}>Submit Message</button>
                        </div>
                    </form>
                </div>
            </Container>
        </div>;
    }
}
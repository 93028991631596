import {Component} from "react";
import {Container} from "@material-ui/core";
import {Link} from "react-router-dom";


export class Download extends Component{
    render() {
        return <div className={'App'}>
            <Container>
                <div className={'row align-items-start my-5'}>
                    <div className={'col-md-6'}>
                        <div className={'py-2'}>
                            <h2> AFRIX for Mobile</h2>
                        </div>
                        <div className={' rounded-3 overflow-hidden'} style={{height:'350px',backgroundColor:'#cbdbe3'}}>
                            <div className={'text-center py-4'}>
                                <img src="/combined.png" alt="IOS Screenshot" style={{width:'100%'}}/>
                            </div>
                        </div>
                        <div className={'mt-4'}>
                            <Link to={'/downloading#ios'} className={'btn btn-afrix'}>Download For IOS</Link>
                            <Link to={'/downloading#android'} className={'btn btn-afrix mx-2'}>Download For Android</Link>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'py-2'}>
                            <h2> AFRIX for Desktop</h2>
                        </div>
                        <div className={' rounded-3 overflow-hidden'} style={{height:'350px',backgroundColor:'#cbdbe3'}}>
                            <div className={'text-center py-4'}>
                                <img src="/screen_macos.png" alt="IOS Screenshot" style={{width:'100%',minHeight:'100%'}}/>
                            </div>
                        </div>
                        <div className={'mt-4'}>
                            <Link to={'/downloading'} className={'btn btn-afrix'}>Download For Macos</Link>
                        </div>

                        <article className={'mt-4 rounded-3 light-bg-dark'}>
                            <div style={{padding:'20px'}}> To use the AFRIX desktop app, Afrix must first be installed
                                on your phone.
                            </div>
                        </article>
                        <h3 className={'mt-4'}>Not on Mac?</h3>
                        <ul style={{listStyle:'none'}}>
                            <li><a href="https://updates.signal.org/desktop/signal-desktop-win-5.28.0.exe"
                                   className="download-primary download-windows"> Afrix for Windows </a></li>
                            <li><a href="#" className="download-primary download-linux"> Afrix for Linux - Debian-based
                                distros </a></li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>;
    }
}
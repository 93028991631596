import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {Privacy} from "./Privacy";
import {BrowserRouter, Routes} from "react-router-dom";
import {Terms} from "./Terms";
import {Route} from "react-router-dom";
import {NavigationBar} from "./NavigationBar";
import {NotFoundRoute} from "./NotFoundRoute";
import {Download} from "./Download";
import {Downloading} from "./Downloading";
import {Developer} from "./Developer";
import './index.css';
import {SocialIcon} from "./SocialIcon";
import {Contact} from "./Contact";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <NavigationBar/>
          <Routes>
              <Route path="/" element={<App />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="download" element={<Download />} />
              <Route path="developers" element={<Developer />} />
              <Route path="contact" element={<Contact />} />
              <Route path="downloading" element={<Downloading />} />
              <Route path="*" exact={true} component={<NotFoundRoute/>} />
          </Routes>

          <div className="position-static App my-5">
              <h2 className="" style={{fontSize:'14px'}}>Designed &amp; Developed by Afrix Ltd</h2>
              <SocialIcon/>
          </div>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
